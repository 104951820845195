<template>
  <div>
    <!-- MODAL -->

    <b-modal
      id="radio-modal"
      ref="radio-modal"
      size="lg"
      content-class="bg-dark text-light  "
      centered
      hide-footer
      header-close-variant="light"
    >
      <template #modal-header="{ close }">
        <b-container fluid>
          <b-row>
            <b-col class="float-left">
              <img
                src="../../assets/trackd-icon-colours.svg"
                class="mb-4 mx-auto text-left mt-2"
                height="25"
                alt="Logo"
              />
            </b-col>

            <b-col class="float-right">
              <b-button
                size="sm"
                variant="link"
                class="float-right"
                @click="close()"
              >
                <img
                  src="../../assets/section-close.svg"
                  class="mx-auto text-right float-right mt-2"
                  height="20"
                  alt="Close"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>

      <b-row>
        <b-col class="text-center mb-5">
          <b-img
            v-if="this.user_image"
            rounded="circle"
            :src="getImagePath(this.user_image, { width: 100, height: 100 })"
            blank-color="#ccc"
            alt="placeholder"
            height="100"
            width="100"
            class="light-grey-border mb-3"
          ></b-img>
          <b-img
            v-else
            rounded="circle"
            blank
            blank-color="#ccc"
            alt="placeholder"
            height="100"
            width="100"
            class="light-grey-border mb-3"
          ></b-img>

          <p class="fw-500 fs-34 lh-36 mb-1">{{artist_name}}</p>
          <p class="fw-200 fs-34 lh-36 mb-2">Radio</p>
          <p class="fw-700 fs-18 lh-21 mb-4">Get a taste of exclusive content<br> from this Artist and Similar Artists!</p>

          <b-button @click="playRadio" variant="outline-light" size="lg" class=" px-5 fw-300 rounded-10 mb-4 fs-24 lh-22 py-3">Start Radio</b-button>

          <br>

          <!-- <b-button variant="link" class="text-light fw-300 fs-18 lh-21">What is Radio?</b-button> -->

        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
//import { mapGetters } from 'vuex';

export default {
  name: "Radio",
  props: ["user_image", "verified", "artist_name"],
  data() {
    return {};
  },
  methods: {
    playRadio() {
      this.$parent.playRadio()
      this.$bvModal.hide("radio-modal");
    }
  },
  mounted: function() {},
};
</script>

<style scoped>
#chipin-form {
  width: 100%;
  margin-top: 10px;
}

.fw-700 {
  font-weight: 700;
  
}
.fw-500 {
  font-weight: 500;
  
}
.fw-300 {
  font-weight: 300;
  
}
.fw-200 {
  font-weight: 200;
  
}
.lh-36 {
  line-height: 36px; 
  
}
.lh-22 {
  line-height: 22px; 
  
}
.lh-21 {
  line-height: 21px; 
  
}
.fs-34 {
  font-size: 34px; 
  
}
.fs-24 {
  font-size: 24px; 
  
}
.fs-18 {
  font-size: 18px; 
}

.modal-content {
  width: auto !important;
  margin: auto;
}

#chipin-form > h1 {
  font-weight: 900 !important;
  font-size: 36px;
  line-height: 21px;
  letter-spacing: 0.152972px;
}

#chipin-form > p {
  font-weight: 300 !important;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.152972px;
}

#chipin-form > h4 {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.152972px;
}

.light-grey-border {
  border: 2px solid #9b9b9b;
}

.red-text {
  color: #ff0000;
}

.dark-text {
  color: #595959;
}

.super-small {
  font-size: 0.7em;
  color: #595959;
}

.rounded-btn {
  border-radius: 10px;
}

.line-height {
  line-height: 1.45em;
}

.included-info {
  display: inline-flex;
  align-items: center;
  float: left;
}

.song-profile-des > p {
  font-weight: 200 !important;
  font-size: 34px;
  line-height: 36px;
}

.song-profile-des > h5 {
  font-weight: 500 !important;
  font-size: 34px;
  line-height: 36px;
}

.song-profile-des > span {
  font-weight: 300 !important;
  font-size: 18px;
  line-height: 24px;
  display: block;
  margin-top: 20px;
}

.months-btn {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
}

.months-btn > button {
  border-radius: 7px;
  font-family: "SFPro";
}

.month-one {
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background: white;
  color: black;
  border: 0.5px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
  width: 50%;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  margin-right: -5px;
  z-index: 2;
}

.month-twelve {
  background: black;
  color: white;
  background: rgba(118, 118, 128, 0.12);
  border: 1px solid #ffffff;
  border-left: none;
  width: 50%;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
}

.subscription {
  font-weight: 300 !important;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.152972px;
}

.select-btn {
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  width: 236px;
  height: 40px;
}

.show-btn {
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  width: 236px;
  height: 40px;
  border: 1px solid #00afdc;
  background-color: transparent;
}

.choose-text {
  font-size: 18px;
  font-weight: 700;
}
</style>
