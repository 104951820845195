<template>
  <b-modal
    :id="'add-to-playlist-modal' + song.id"
    size="lg"
    centered
    hide-footer
    title-sr-only
    content-class="bg-dark text-light text-center"
    header-close-variant="light"
    modal-class="dot-dot-dot-modal"
    title="Login"
    @hide="onClose"
  >
    <template #modal-header="{ close }">
      <b-container fluid>
        <b-row class="text-center">
          <!-- Required for equal spacing -->
          <b-col></b-col>

          <b-col cols="12">
            <img
              src="../../../../assets/trackd-icon-colours.svg"
              class="mb-4 float-right mt-2"
              @click="close()"
              height="35"
              alt="Logo"
            />
            <h4 class="font-weight-lighter text-left h2 mt-5 mb-5 mb-md-0">
              Add To Playlist
            </h4>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <b-container fluid class="pb-0">
      <b-row>
        <div class="col-11 pl-3">
          <div v-if="success">
            <p class="font-weight-light text-success">
              Song has been added to playlist.
            </p>
          </div>
          <div v-if="error">
            <p class="font-weight-light text-danger">
              There was a problem. please try again.
            </p>
          </div>

          <b-form class="pb-2 mr-3 add-to-playlist-modal-content">

            <b-button 
                class="py-2 px-1 mb-2 text-left text-light font-weight-light btn-add-link"
                block
                @click="$bvModal.show('add-playlist-modal' + song.id)"
                v-b-modal.add-playlist-modal
                variant="link"
                >
                <img
                  src="../../../../assets/ancillary/new-playlist.svg"
                  class="pr-2 ml-1 move-up-icon text-left"
                  height="60"
                  alt="Logo"
                />
                New Playlist
            </b-button>

            <b-button v-for="(playlist, index) in this.playlists" :key="index"
                class="py-2 px-1 mb-2 text-left text-light font-weight-light btn-add-link"
                block
                @click="ExistingPlaylist(playlist.id)"
                variant="link"
                >
                <img
                  src="../../../../assets/ancillary/new-playlist.svg"
                  class="pr-2 ml-1 move-up-icon text-left"
                  height="60"
                  alt="Logo"
                />
                {{ playlist.name }}
            </b-button>

          </b-form>
        </div>
      </b-row>
    </b-container>
    <AddPlaylist v-bind:song="song"></AddPlaylist>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddPlaylist from "./add-playlist.vue";

export default {
  name: "AddToPlaylist",
  props: ["song"],
  components: { AddPlaylist },
  data() {
    return {
      success: false,
      error: false,
      playlists: [],
    };
  },
  methods: {
    ...mapGetters(["isAuthenticated"]),
    ...mapActions("Playlist", ["get_all_playlists", "playlist_add_song"]),
    onClose() {
      this.success = false;
      this.error = false;
    },
    ExistingPlaylist(playlist) {
      this.error = false;
      this.success = false;

      this.playlist_add_song([playlist, {song: this.song.id}])
        .then((res) => {
          res;
          this.success = true;
          this.responseMsg = res.data.status;
          this.responseClass = "text-success";

          setTimeout(() => {
            this.$bvModal.hide('add-to-playlist-modal' + this.song.id)
            this.$router.push({ name: "Playlist", params: { id: playlist } });
          }, 2500);
        })
        .catch((error) => {
          

          if (error.data.errors) {
            this.responseMsg = error.data.errors[0];
            this.responseClass = "text-danger";
          } else if (error.data.non_field_errors) {
            this.responseMsg = error.data.non_field_errors[0];
            this.responseClass = "text-danger";
          } else {
            this.responseMsg = error.data.message;
            this.responseClass = "text-danger";
          }
        });
    },
  },
  mounted() {
    this.success = false;
    this.error = false;

    if (this.isAuthenticated()) {
      this.get_all_playlists(this.song.id).then((res) => {
        this.playlists = [...res.data];
      });
    }
  },
};
</script>

<style>
.dot-dot-dot-modal .modal-content {
  width: 400px;
  margin: auto;
}

.add-to-playlist-modal-content {
  max-height: 400px;
  overflow: scroll;
}

.forget-link {
  text-decoration: none;
}
.rect-box {
  border-radius: 0 !important;
}

.text-underline {
  text-decoration: underline;
}
.text-underline:hover {
  text-decoration: none;
}

.btn-rounded {
  border-radius: 10px;
}

.h2 {
  font-weight: 400;
  font-size: 24px;
  line-height: 21px;
}

.confirm-text {
  font-weight: 300;
  font-size: 24px;
  line-height: 21px;
  color: #9b9b9b;
}

.btn-add-link {
font-size: 18px;
line-height: 21px;
}

.btn-add-link:hover {
  border-radius: 20px;
  background-color: #222222;
  text-decoration: none;
}

.text-input {
  background-color: #222222;
  color: #9b9b9b;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  font-weight: 300;
}

.form-control:focus {
  background-color: #222222;
  color: #9b9b9b;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #9b9b9b;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #9b9b9b;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #9b9b9b;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #9b9b9b;
}

.b-dropdown-form:focus {
  outline: none !important;
}
</style>
