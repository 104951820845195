<template>
  <div>
    <div v-if="loaded" :class="{'header-margin': !isAuthenticated && showHeaderMargin()}">
      <Banner v-bind:type="'user'" v-bind:asset="artist"></Banner>

      <b-container class="artist-container">
        <b-row>
        <b-col
            cols="3"
            sm="2"
            md="3"
            offset="5"
            offset-sm="4"
            offset-md="4"
            offset-lg="3"
            offset-xl="3">


            <b-badge
              v-if="artist.userprofile.supporter"
              pill
              variant="danger-outline"
              class="mt-0 text-uppercase text-danger border-solid border-danger mr-1 border-danger-1 c-profile-badges c-supporter-badge"
              >Supporter</b-badge
            >

        </b-col>
        <b-col cols="4" sm="5" lg="6" class="pr-0">

            <b-badge
              pill
              href="#"
              @click="openRadio()"
              variant="light-outline"
              class="text-uppercase text-light border-solid border-light mr-1 border-light-1 c-supporter-badge float-right radio-btn"
              ><img src="../assets/radio-symbol.svg" class="mr-2 radio-symbol" />Start Radio</b-badge
            >

          </b-col>  
        </b-row>

        <b-row id="profile_player">
          <b-col
            cols="6"
            sm="4"
            md="4"
            lg="3"
            xl="3"
            class="text-center positioning mt-4 c-photo-container"
          >
            <b-img
              :src="
                getImagePath(artist.userprofile.photo, {
                  width: 150,
                  height: 150,
                })
              "
              blank-color="red"
              class="photo-border mx-auto text-center c-artist-photo"
              width="250"
              height="250"
              alt="placeholder"
            ></b-img>
            <img
              src="../assets/verified.svg"
              class="artist-verified"
              alt="Verified Badge"
            />
          </b-col>

          

          <b-col cols="6" sm="8" md="8" lg="5" xl="5" class="artist-profile">

            <b-row class="stats-section">
              <b-col
                cols="3"
                md="2"
                class="font-weight-light text-center pl-0 c-artist-stat"
              >
                <p class="stats-number">{{ artist.stats.song_count }}</p>
                <p class="text-smaller">Songs</p>
              </b-col>
              <b-col
                cols="3"
                md="2"
                class="font-weight-light text-center c-artist-stat"
              >
                <p class="stats-number">{{ artist.stats.listeners }}</p>
                <p class="text-smaller">Listeners</p>
              </b-col>
              <b-col
                cols="3"
                md="2"
                class="font-weight-light text-center c-artist-stat"
              >
                <p class="stats-number">{{ artist.stats.followers }}</p>
                <p class="text-smaller">Followers</p>
              </b-col>
              <b-col
                cols="3"
                md="2"
                class="font-weight-light text-center c-artist-stat"
              >
                <p class="stats-number">{{ artist.stats.following }}</p>
                <p class="text-smaller">Following</p>
              </b-col>
            </b-row>
          </b-col>

          <b-col lg="3" xl="3" class="displayed-on-desktop social-profiles offset-1">

            <a v-if="artist.userprofile.site" target="_blank" :href="'https://' + artist.userprofile.site">
              <img
                src="../assets/www-active.svg"
                alt="chipin"
                class="float-right social-icons"
              />
              <!--<img
                v-else
                src="../assets/www.svg"
                alt="website"
                class="float-right social-icons"
              />-->
            </a>
            <a v-if="artist.userprofile.instagram" target="_blank" :href="artist.userprofile.instagram">
              <img
                src="../assets/instagram-active.svg"
                alt="chipin"
                class="float-right social-icons"
              />
              <!--<img
                v-else
                src="../assets/instagram.svg"
                alt="instagram"
                class="float-right social-icons"
              />-->
            </a>
            <a v-if="artist.userprofile.twitter" target="_blank" :href="artist.userprofile.twitter">
              <img
                
                src="../assets/twitter-active.svg"
                alt="chipin"
                class="float-right social-icons"
              />
              <!--<img
                v-else
                src="../assets/twitter.svg"
                alt="twitter"
                class="float-right social-icons"
              />-->
            </a>
            <a v-if="artist.userprofile.facebook" target="_blank" :href="artist.userprofile.facebook">
              <img
                
                src="../assets/facebook-active.svg"
                alt="chipin"
                class="float-right social-icons"
              />
              <!--<img
                v-else
                src="../assets/facebook.svg"
                alt="facebook"
                class="float-right social-icons"
              />-->
            </a>
            <a v-if="artist.userprofile.youtube" target="_blank" :href="artist.userprofile.youtube">
              <img
                
                src="../assets/youtube-active.svg"
                alt="chipin"
                class="float-right social-icons"
              />
              <!--<img
                v-else
                src="../assets/youtube.svg"
                alt="youtube"
                class="float-right social-icons"
              />-->
            </a>
            <a v-if="artist.userprofile.tiktok" target="_blank" :href="artist.userprofile.tiktok">
              <img
                
                src="../assets/tiktok-active.svg"
                alt="chipin"
                class="float-right social-icons"
              />
              <!--<img
                v-else
                src="../assets/youtube.svg"
                alt="youtube"
                class="float-right social-icons"
              />-->
            </a>
          </b-col>

          <b-col xl="1"></b-col>

          <b-col lg="3" xl="3" class="displayed-on-desktop social-profiles d-lg-none">
            <a v-if="artist.userprofile.site" target="_blank" :href="'https://' + artist.userprofile.site">
              <img
                src="../assets/www-active.svg"
                alt="chipin"
                class="float-right social-icons"
              />
              <!--<img
                v-else
                src="../assets/www.svg"
                alt="website"
                class="float-right social-icons"
              />-->
            </a>
            <a v-if="artist.userprofile.instagram" target="_blank" :href="artist.userprofile.instagram">
              <img
                src="../assets/instagram-active.svg"
                alt="chipin"
                class="float-right social-icons"
              />
              <!--<img
                v-else
                src="../assets/instagram.svg"
                alt="instagram"
                class="float-right social-icons"
              />-->
            </a>
            <a v-if="artist.userprofile.twitter" target="_blank" :href="artist.userprofile.twitter">
              <img
                src="../assets/twitter-active.svg"
                alt="chipin"
                class="float-right social-icons"
              />
              <!--<img
                v-else
                src="../assets/twitter.svg"
                alt="twitter"
                class="float-right social-icons"
              />-->
            </a>
            <a v-if="artist.userprofile.facebook" target="_blank" :href="artist.userprofile.facebook">
              <img
                src="../assets/facebook-active.svg"
                alt="chipin"
                class="float-right social-icons"
              />
              <!--<img
                v-else
                src="../assets/facebook.svg"
                alt="facebook"
                class="float-right social-icons"
              />-->
            </a>
            <a v-if="artist.userprofile.youtube" target="_blank" :href="artist.userprofile.youtube">
              <img
                src="../assets/youtube-active.svg"
                alt="chipin"
                class="float-right social-icons"
              />
              <!--<img
                v-else
                src="../assets/youtube.svg"
                alt="youtube"
                class="float-right social-icons"
              />-->
            </a>
            <a v-if="artist.userprofile.tiktok" target="_blank" :href="artist.userprofile.tiktok">
              <img
                src="../assets/tiktok-active.svg"
                alt="chipin"
                class="float-right social-icons"
              />
              <!--<img
                v-else
                src="../assets/youtube.svg"
                alt="youtube"
                class="float-right social-icons"
              />-->
            </a>
          </b-col>
        </b-row>

        <b-row
          cols="12"
          sm="12"
          md="12"
          class="positioning l-social-and-data displayed-on-mobile mt-1 mt-md-0"
        >
          <b-col class="pl-md-0 pl-lg-1">
            <b-row class="l-last-access-and-social">
              <div class="font-weight-lighter float-left ">
                <span class="lead text-grey c-last-artist-login">
                  <img
                    src="../assets/overview-icon.svg"
                    class="c-last-artist-login-logo"
                    height="30"
                    alt="Logo"
                  />
                  {{ artist.last_login_pretty }}
                </span>
              </div>

              <div class="displayed-on-mobile social-profiles">
                
                  <a v-if="artist.userprofile.site" target="_blank" :href="'https://' + artist.userprofile.site">
                  <img
                    src="../assets/www-active.svg"
                    alt="chipin"
                    class="float-right displayed-on-mobile social-icons"
                  />
                  <!--<img
                    v-else
                    src="../assets/www.svg"
                    alt="website"
                    class="float-right displayed-on-mobile social-icons"
                  />-->
                </a>
                  <a v-if="artist.userprofile.instagram" target="_blank" :href="artist.userprofile.instagram">
                    <img
                      src="../assets/instagram-active.svg"
                      alt="chipin"
                      class="float-right displayed-on-mobile social-icons"
                    />
                    <!--<img
                      v-else
                      src="../assets/instagram.svg"
                      alt="instagram"
                      class="float-right displayed-on-mobile social-icons"
                    />-->
                  </a>

                  <a v-if="artist.userprofile.youtube" target="_blank" :href="artist.userprofile.youtube">
                    <img
                      src="../assets/youtube-active.svg"
                      alt="chipin"
                      class="float-right displayed-on-mobile social-icons youtube"
                    />
                    <!--<img
                      v-else
                      src="../assets/youtube.svg"
                      alt="youtube"
                      class="float-right displayed-on-mobile social-icons youtube"
                    />-->
                  </a>
                  <a v-if="artist.userprofile.facebook" target="_blank" :href="artist.userprofile.facebook">
                    <img
                      
                      src="../assets/facebook-active.svg"
                      alt="chipin"
                      class="float-right displayed-on-mobile social-icons"
                    />
                    <!--<img
                      v-else
                      src="../assets/facebook.svg"
                      alt="facebook"
                      class="float-right displayed-on-mobile social-icons"
                    />-->
                  </a>
                  <a v-if="artist.userprofile.twitter" target="_blank" :href="artist.userprofile.twitter">
                    <img
                      src="../assets/twitter-active.svg"
                      alt="chipin"
                      class="float-right displayed-on-mobile social-icons twitter"
                    />
                    <!--<img
                      v-else
                      src="../assets/twitter.svg"
                      alt="twitter"
                      class="float-right displayed-on-mobile social-icons twitter"
                    />-->
                  </a>
                  <a v-if="artist.userprofile.tiktok" target="_blank" :href="artist.userprofile.tiktok">
                    <img
                      src="../assets/tiktok-active.svg"
                      alt="chipin"
                      class="float-right displayed-on-mobile social-icons twitter"
                    />
                    <!--<img
                      v-else
                      src="../assets/twitter.svg"
                      alt="twitter"
                      class="float-right displayed-on-mobile social-icons twitter"
                    />-->
                  </a>
              </div>
            </b-row>

            <div
              class="font-weight-lighter l-location-container"
              v-if="artist.userprofile.location"
            >
              <span
                v-if="artist.userprofile.location"
                class="lead text-grey c-artist-location"
              >
                <img
                  src="../assets/location-icon.svg"
                  class="c-artist-location-logo"
                  height="30"
                  alt="Logo"
                />
                {{ artist.userprofile.location }}
              </span>
            </div>
          </b-col>
        </b-row>

        <b-row
          cols="12"
          sm="6"
          md="4"
          class="positioning wide l-social-and-data displayed-on-desktop pl-2"
        >
          <b-col cols="5" sm="3" class="text-light pl-2">
            <div class="font-weight-lighter ">
              <span class="lead text-grey c-last-artist-login">
                <img
                  src="../assets/overview-icon.svg"
                  class="c-last-artist-login-logo"
                  height="30"
                  alt="Logo"
                />
                {{ artist.last_login_pretty }}
              </span>
            </div>
          </b-col>
          <b-col cols="7" md="7" lg="6" class="pl-xl-5">
            <div class="font-weight-lighter artist-location-adjust" v-if="artist.userprofile.location">
              <span
                v-if="artist.userprofile.location"
                class="lead text-grey c-artist-location"
              >
                <img
                  src="../assets/location-icon.svg"
                  class="c-artist-location-logo"
                  height="30"
                  alt="Logo"
                />
                <span> {{ artist.userprofile.location }}</span>
              </span>
            </div>
          </b-col>
        </b-row>

        <b-row v-if="artist.userprofile.bio" cols="12" class="mt-4 mb-0 mb-md-3 positioning wide">
          <b-col cols="12" sm="12" md="12" class="artist-bio-adjust">
            <p class="c-artist-bio mb-0 mb-md-4 mb-lg-4" style="white-space: pre-line !important;">
              {{ artist.userprofile.bio }}
            </p>
          </b-col>
          <div v-if="artist.userprofile.bio && artist.userprofile.chipin_enabled && artist.chipin_plus && artist.locked || artist.userprofile.bio && !artist.chipin_plus" 
            class="border-gradient" style="margin-left: 15px; padding-right: 20px;">
          </div>
        </b-row>


        <b-row
          
          v-if="artist.userprofile.chipin_enabled"
          cols="12"
          class="positioning"
        >
          <b-col v-if="artist.chipin_plus && artist.locked">
            <div class="border-gradient support-gradient"></div>
          </b-col>
          <b-col
            v-if="artist.userprofile.chipin_enabled"
            cols="12"
            class="chipin-support px-3 px-md-0 px-lg-3 px-xl-0"
          >
            <b-button
              v-if="artist.chipin_plus && artist.locked"
              @click="launch_chipin_plus_modal()"
              variant="link"
              class="support-btn pl-0 pr-0"
            >
              <img
                src="../assets/chipin-plus-badge.svg"
                alt="chipin"
                class="support-img"
              />
            </b-button>
            <b-button
              v-if="!artist.chipin_plus"
              @click="launch_chipin_modal()"
              variant="link"
              class="support-btn"
            >
              <img
                src="../assets/chipin-badge.svg"
                height="58"
                alt="chipin"
                class="support-img"
              />
            </b-button>
            <div class="support-artist mt-3 mt-md-0 mt-lg-0">
              <p
                v-if="artist.chipin_plus && artist.locked || !artist.chipin_plus"
                class="chipin-support-title pb-0 mt-1 mt-xl-0 pt-0 text-light font-weight-lighter"
              >
                Support this Artist Now!
              </p>

              <span v-if="artist.chipin_plus && artist.locked"
                class="text-light font-weight-lighter pb-0 mb-0 mt-1 pt-0 chipin-text"
                >Click or tap here to Subscribe and unlock all this Artist’s content</span
              >

              <span v-if="!artist.chipin_plus"
                class="text-light font-weight-lighter pb-0 mb-0 mt-0 pt-0 chipin-text chipin-text--mobile"
                >Tap here to subscribe</span
              >

              <span v-if="!artist.chipin_plus"
                class="text-light font-weight-lighter pb-0 mb-0 mt-0 pt-0 chipin-text"
                >Click or tap here to show your appreciation</span
              >
            
            </div>

          </b-col>
        </b-row>

        <b-row
          v-if="artist.most_listened.length > 0"
          style=""
          class="pb-0 pb-md-4 positioning c-most-listened wide"
        >
          <b-col cols="12">
            <h3 class="text-light font-weight-lighter section-title mt-xl-2">
              Most Popular Songs
              <b-button
                variant="link"
                class="float-right text-light show-all-btn font-weight-lighter pr-0 pr-md-2"
                @click="show_all_link()"
                >Show All</b-button
              >
            </h3>
            <div class="border-gradient"></div>
          </b-col>

          <b-col>
            <b-row id="most-popular-songs" class="overflow-auto ">
              <div
                id="c-song-from-artist"
                class="card-carousel-cards c-most-popular-songs"
              >
                <b-col
                  v-for="song in artist.most_listened"
                  :key="song.id"
                  class="text-center pr-0 c-most-popular-songs__song"
                  style="display: inline-grid;"
                >
                  <img
                    v-if="song.chipin_plus && song.locked"
                    src="../assets/chipin+-locked-light.svg"
                    class="song-locked"
                    height="30"
                    alt="Logo"
                  />

                  <player
                    v-if="isOwn(request_user_id, artist.id)"
                    :image="song.song_image"
                    :file="song.song_url"
                    :id="song.id"
                    :title="song.song_name"
                    :username="artist.username"
                    :queue="artist.most_listened"
                  ></player>
                  <player
                    v-else
                    :chipin_plus="song.chipin_plus"
                    :locked="song.locked"
                    :image="song.song_image"
                    :file="song.song_url"
                    :id="song.id"
                    :title="song.song_name"
                    :username="artist.username"
                    :artist_id="artist.userprofile.user_id"
                    :queue="artist.most_listened"
                  ></player>
                  <b-button
                    v-if="song.retrak"
                    pill
                    size="sm"
                    variant="warning"
                    class="text-uppercase text-light text-small py-0"
                    style="position: relative; top: -24px;"
                    >Re-Track</b-button
                  >

                  <img
                    src="../assets/trackd-icon-dark.svg"
                    class="align-top top-arrow mb-1 c-arrow-up-disk"
                    alt="Logo"
                  />

                  <p
                    class="text-light pb-0 mb-0 font-weight-light small l-popular-artist-song-description"
                  >
                    <router-link
                      class="text-light popular-artist-song"
                      :to="{
                        name: 'SongProfile',
                        params: { username: artist.username, id: song.id },
                      }"
                      v-html="
                        elipsis(song.song_name, mostPopularSongsMaxTextLength)
                      "
                    ></router-link>
                    <br />
                    <span class="popular-artist-name">{{
                      artist.username
                    }}</span>
                  </p>
                </b-col>

                <div class="pl-4 col" v-if="artist.most_listened.length > 10">
                  <b-btn id="song-more-btn" class="song-more-btn">
                    <div>
                      <img src="../assets/library_show_all_icon.png" />
                      <p class="mt-1 font-weight-light">More</p>
                    </div>
                  </b-btn>
                </div>
              </div>
            </b-row>
          </b-col>
        </b-row>

        <b-row
          v-if="artist.featured_tracks.length > 0"
          class="positioning wide l-featured-songs row pt-2 pb-1 py-md-2 positioning profile-section pb-md-3"
        >
          <b-col>
            <h3
              class="text-light mb-0 mb-md-0 mb-lg-3 font-weight-lighter tab-title section-heading"
            >
              Featured Songs
              <b-button
                variant="link"
                class="float-right text-light show-all-btn font-weight-lighter"
                @click="show_all_featured_link()"
                >Show All</b-button
              >
            </h3>
            <div class="border-gradient"></div>

            <b-row class="featured-songs-list wide">
              <b-col
                cols="12"
                md="12"
                lg="12"
                xl="6"
                v-for="(song, index) in artist.featured_tracks"
                :key="index"
                class="text-center text-light text-left song-card c-featured-song"
              >
                <b-col cols="3" md="3" sm="3" lg="3" xl="4" class="pr-0 pl-xl-0">
                  <player
                    v-bind:chipin_plus="song.chipin_plus"
                    v-bind:locked="song.locked"
                    v-bind:image="song.image"
                    v-bind:file="song.song_url"
                    v-bind:id="song.id"
                    v-bind:title="song.name"
                    v-bind:username="song.username"
                    v-bind:queue="artist.featured_tracks"
                    v-bind:artist_id="artist.userprofile.user_id"
                  ></player>
                </b-col>

                <b-col cols="9" md="9" sm="9" lg="9" xl="8" class="pl-3 pl-sm-2 pl-md-3 pl-xl-0 pr-xl-0 song-info-adjust">
                  <div class="feature-div" style="margin-bottom: -15px">
                    <div class="feature-left float-left text-left mt-1">
                      <p
                        class="text-light pb-0 mb-2 mb-md-0 mr-0 font-weight-light song-header"
                      >
                        <img
                          v-if="song.chipin_plus && song.locked"
                          src="../assets/chipin+-locked-light.png"
                          class="align-top mr-0 chipin-locked-icon"
                          alt="Logo"
                        />
                        <span class="c-feature-song-title text-left ml-2 ml-md-3 ml-lg-2">
                          <router-link
                            class="text-light song-name"
                            :to="{
                              name: 'SongProfile',
                              params: {
                                username: artist.username,
                                id: song.id,
                              },
                            }"
                            v-html="elipsis(song.name, featuredSongsMaxTextLength)"
                            >{{ song.name }}</router-link
                          >
                        </span>
                      </p>
                    </div>
                    <div class="float-right c-song-indicators">
                      <img
                        v-if="song.liked"
                        src="../assets/heart-filled.svg"
                        @click="unlikeSong(song)"
                      
                        class="text-danger more-icons heart"
                      />
                      <img
                        v-if="!song.liked"
                        src="../assets/heart.svg"
                        @click="likeSong(song)"
                        
                        class="text-light more-icons heart"
                      />
                      <SongActions
                        v-bind:song="song"
                        v-bind:index="index"
                        v-bind:delete_type="'featured'"
                      ></SongActions>
                    </div>
                  </div>
                  <div class="feature-div">
                    <div class="feature-left float-left text-left">
                      <p class="mb-2 mt-2" :class="{'mt-xl-3': song.name.length > 25}">
                        <b-button
                          v-if="song.rt_badge"
                          pill
                          variant="warning"
                          class="text-uppercase text-light song-btns mr-1 rtcl-btns"
                          >RT</b-button
                        >
                        <b-button
                          v-if="song.cl_badge"
                          pill
                          variant="warning"
                          class="text-uppercase text-light song-btns ml-1 rtcl-btns"
                          >CL</b-button
                        >

                        <span class="artist-name ml-1">{{
                          song.username
                        }}</span>
                      </p>
                    </div>
                    <div class="float-right mt-1 mt-md-3 mt-lg-1 mb-2 c-song-information">
                      <b-button
                        v-if="song.explicit"
                        size="sm"
                        variant="outline-light"
                        class="text-uppercase rounded-5 song-btns py-0 btn-18 mt-0"
                        disabled="true"
                        >18</b-button
                      >
                      <p class="pb-0 mb-0 likes-count float-right">
                        {{ song.likes }}
                      </p>
                    </div>
                  </div>
                  <div class="feature-div song-des-row">
                    <div class="feature-left float-left text-left mt-0">
                      <p class="song-des font-weight-lighter text-left" v-html="elipsis(song.description, songDescriptionMaxTextLength)">
                        {{ song.description }}
                      </p>
                    </div>
                    <div class="float-right">
                      <img
                        v-if="song.reward"
                        src="../assets/song-recorder.svg"
                        blank-color="#ccc"
                        height="75"
                        width="60"
                        class="img-fluid song-recorder"
                        alt="placeholder"
                      />
                    </div>
                  </div>
                </b-col>
              </b-col>
              <b-col
                cols="12"
                md="12"
                lg="12"
                xl="6"
                class="text-left mt-0"
                v-if="isOwn(request_user_id, artist.userprofile.user_id)"
              >
                <b-button variant="link" v-b-modal="'add-featured-song-modal'" class="pl-0">
                  <img
                    src="../assets/ancillary/new-playlist.svg"
                    class="pr-2 move-up-icon text-left add-featured-song"
                    height="150px"
                    width="150px"
                    alt="Logo"
                  />
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row
          class="section-spacing wide positioning py-1 py-md-3 positioning profile-section pb-0 pb-md-3"
        >
          <b-col>
            <h3
              class="text-light mb-2 mb-md-3 mb-xl-2 font-weight-lighter tab-title section-heading"
            >
              Genre
            </h3>
            <div class="border-gradient"></div>

            <div class="overflow-auto">
              <ul
                v-if="artist.genres.length > 0"
                class="list-inline wrap l-desktop-genres"
              >
                <b-button
                  v-for="(tag, index) in artist.genres"
                  :key="index"
                  size="lg"
                  variant="outline-light"
                  class="m-2 genre-box font-weight-light"
                  @click="tag_click"
                >
                  {{ tag.tag }}
                </b-button>
              </ul>
              <div
                v-if="artist.genres.length > 0"
                class="l-mobile-genres row overflow-auto mt-1"
              >
                <div
                  v-for="(tag, index) in artist.genres"
                  :key="index"
                  class="text-light"
                >
                  <img
                    src="../assets/hash-tag.svg"
                    class=""
                    style="margin-left:10px;"
                    height="15 "
                    alt="Logo"
                  />
                  {{ tag.tag }}
                </div>
              </div>
              <p v-else class="comment-des  py-2 pb-3 text-light font-weight-lighter mb-0">
                Awaiting Genres
              </p>
            </div>
          </b-col>
        </b-row>

        <b-row
          class="row positioning wide profile-section pt-1 pb-0 pb-md-3 l-comments-section"
        >
          <b-col class="text-light">
            <h3
              class="text-light mb-2 font-weight-lighter tab-title section-heading mt-1 mt-md-0"
            >
              Comments
              <b-button
                v-if="artist.comments.length > 4"
                variant="link"
                class="float-right text-light show-all-btn font-weight-lighter pr-0 pr-md-2"
                @click="comment_show_all_link()"
                >Show All</b-button
              >
            </h3>
            <div class="border-gradient"></div>

            <div v-if="artist.comments.length > 0" class="comment-section">
              <!-- Show only the last 4 comments -->
              <p
                class="comment-des font-weight-lighter pt-0 pt-md-3 pt-xl-2"
                v-for="{ comment, i } in artist.comments.slice(
                  artist.comments.length - 4,
                  artist.comments.length
                )"
                :key="i"
              >
                "{{ comment }}"
              </p>
            </div>
            <p v-else class="comment-des">No comments yet</p>
          </b-col>
        </b-row>

        <b-row
          v-if="artist.userprofile.chipin_enabled && !artist.chipin_plus"
          class="wide row py-3 positioning profile-section pb-0 pb-md-3"
        >
          <b-col class="text-light l-hall-of-fame">
            <h3
              class="text-light mb-3 font-weight-lighter tab-title section-heading"
            >
              ChipIn<span class="c-chipin-copyright__title"
                ><sup>&reg;</sup></span
              >
              Wall of Fame
            </h3>
            <div class="border-gradient"></div>

            <div class="font-weight-normal c-chipin-description pb-3">
              Show your appreciation to this Artist.
              <b-button
                v-if="artist.chipin_plus"
                variant="link"
                @click="launch_chipin_plus_modal()"
                class="chipin-link text-light pl-0 c-chipin-description text-underline"
                >ChipIn<sup>&reg;</sup> Now!</b-button
              >
              <b-button
                v-if="!artist.chipin_plus"
                variant="link"
                @click="launch_chipin_modal()"
                class="chipin-link text-light pl-0 c-chipin-description text-underline"
                >ChipIn<sup>&reg;</sup> Now!</b-button
              >
            </div>
            <h3 class="recent-text font-weight-light">Recent Contributors</h3>

            <div
              class="recent-text l-empty-space-wall-of-fame"
              v-if="artist.chipin_transactions.length == 0"
            >
              No contributors
            </div>
            <div class="l-wall-of-fame-container">
              <b-row
                v-for="(chipin, i) in artist.chipin_transactions"
                :key="i"
                class="l-wall-of-fame"
              >
                <b-col cols="4" sm="3" md="3" class="contrib-left">
                  <p
                    class="text-light font-weight-lighter"
                    :style="'opacity: ' + (100 - i * 12) + '%;'"
                  >
                    {{ chipin.username }}
                  </p>
                </b-col>
                <b-col cols="4" sm="3" md="3" class="contrib-middle">
                  <p
                    class="text-light font-weight-lighter"
                    :style="'opacity: ' + (100 - i * 12) + '%;'"
                  >
                    {{ chipin.location }}
                  </p>
                </b-col>
                <b-col cols="4" sm="3" md="3" class="contrib-right">
                  <p
                    class="text-light font-weight-lighter"
                    :style="'opacity: ' + (100 - i * 12) + '%;'"
                  >
                    {{ chipin.amount }}
                  </p>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>

        <b-row
          class="row py-3 positioning wide profile-section pb-0 pb-md-3 positioning pb-5"
        >
          <b-col class="text-light">
            <h3
              class="text-light mb-2 font-weight-lighter tab-title section-heading"
            >
              Artist Information
            </h3>
            <div class="border-gradient"></div>

            <ul class="list-unstyled mt-3 pt-3">
              <li class="font-weight-lighter c-artist-information">
                <span class="mb-0 pb-0">Copyright: {{ artist.copyright }}</span>
              </li>
              <li
                v-if="artist.publisher"
                class="font-weight-lighter c-artist-information"
              >
                <span class="mb-0 pb-0">Publisher: {{ artist.publisher }}</span>
              </li>
              <li
                v-if="artist.rights_society"
                class="font-weight-lighter c-artist-information"
              >
                <span class="mb-0 pb-0">
                  Rights Society: {{ artist.rights_society }}
                </span>
              </li>
            </ul>
          </b-col>
        </b-row>

        <chipin
          v-if="artist.userprofile.chipin_enabled && !artist.chipin_plus"
          v-bind:user_id="artist.id"
          v-bind:username="artist.username"
          v-bind:stripe_type="artist.made_on_stripe_account"
          v-bind:show_modal="$route.query.chipin"
        ></chipin>

        <chipinplus
          v-if="artist.userprofile.chipin_enabled && artist.chipin_plus"
          v-bind:user_id="artist.id"
          v-bind:username="artist.username"
          v-bind:user_image="artist.userprofile.photo"
          v-bind:stripe_type="artist.made_on_stripe_account"
          v-bind:show_modal="$route.query.chipinplus"
        ></chipinplus>

        <chipinplustellmemore
          v-if="artist.userprofile.chipin_enabled && artist.chipin_plus"
        ></chipinplustellmemore>

        <chipinsuccess
          v-if="$route.query.success && !$route.query.chipinplus"
        ></chipinsuccess>
        <chipincancel
          v-if="$route.query.cancel && !$route.query.chipinplus"
        ></chipincancel>

        <chipinplussuccess
          v-if="$route.query.success && $route.query.chipinplus"
          v-bind:photo="artist.userprofile.photo"
        ></chipinplussuccess>

        <chipinpluscancel
          v-if="$route.query.cancel && $route.query.chipinplus"
          v-bind:photo="artist.userprofile.photo"
        ></chipinpluscancel>

        <AddFeaturedSong></AddFeaturedSong>
      </b-container>
    </div>
    <AccessModal></AccessModal>
    <RadioModal v-bind:user_image="artist.userprofile.photo" v-bind:verified="artist.userprofile.verified" v-bind:artist_name="artist.username"></RadioModal>
    <RadioSubscribe v-bind:user_image="artist.userprofile.photo" v-bind:verified="artist.userprofile.verified" v-bind:artist_name="artist.username" v-bind:user_id="artist.id"></RadioSubscribe>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import player from "../components/player.vue";
import chipin from "../components/chipin.vue";
import chipinsuccess from "../components/chipin-success.vue";
import chipincancel from "../components/chipin-cancel.vue";
import chipinplus from "../components/chipinplus.vue";
import chipinplustellmemore from "../components/chipinplus-tellmemore.vue";
import chipinplussuccess from "../components/chipinplus-success.vue";
import chipinpluscancel from "../components/chipinplus-cancel.vue";
import AccessModal from "../components/modal/Access.vue";
import SongActions from "../components/ancillary/artist/song/actions.vue";
import AddFeaturedSong from "../components/ancillary/artist/add-featured-song.vue";
import Banner from "../components/profile/Banner.vue";
import RadioModal from "../components/modal/Radio.vue";
import RadioSubscribe from "../components/modal/RadioSubscribe.vue";

export default {
  name: "Artist",
  data() {
    return {
      loaded: false,
      artist: null,
      liked: null,
      metainfo: undefined
    };
  },
  components: {
    player,
    chipin,
    chipinsuccess,
    chipincancel,
    chipinplus,
    chipinplustellmemore,
    chipinplussuccess,
    chipinpluscancel,
    //ArtistActions,
    SongActions,
    AddFeaturedSong,
    Banner,
    AccessModal,
    RadioModal,
    RadioSubscribe
  },
  metaInfo() {
      return this.metainfo
  },
  methods: {
    ...mapActions("Artist", {
      artistapi: "artist",
      artistwebapi: "artistweb",
    }),
    ...mapActions("User", ["like_user", "unlike_user"]),
    ...mapActions("Song", ["like_song", "unlike_song"]),
    ...mapGetters(["my_user_id", "isAuthenticated"]),
    ...mapActions("Player", ["radio_queue"]),
    ...mapMutations("Player", ["resetPlayer"]),
    ...mapMutations(["kochavaEvent"]),
    openRadio() {

      if (this.isAuthenticated()) {
        this.$bvModal.show("radio-modal");
      } else {
        this.$bvModal.show("login-modal");
      }
    },
    showHeaderMargin() {
      if (this.$route.name === 'Artist' || this.$route.name === 'ArtistV3' || this.$route.name === 'ChipIn') {
        return true;
      } else {
        return false;
      }
    },
    playRadio() {
      
      if (this.isAuthenticated()) {
        this.resetPlayer();
        this.radio_queue(["user", this.artist.id]);
      } else {
        this.$bvModal.show("login-modal");
      }
      
    },
    followArtist() {
      if (this.isAuthenticated()) {
        this.artist.liked = true;
        this.artist.stats.followers += 1;
        this.like_user(this.artist.id);
      } else {
        this.$store.commit('storeNextUrl', this.$route.path)
        this.$bvModal.show("login-modal");
      }
    },
    unfollowArtist() {
      this.artist.liked = false;
      this.artist.stats.followers -= 1;
      this.unlike_user(this.artist.id);
    },
    likeSong(song) {
      if (this.isAuthenticated()) {
        song.liked = true;
        song.likes += 1;
        this.like_song(song.id);
      } else {
        this.$store.commit('storeNextUrl', this.$route.path)
        this.$bvModal.show("login-modal");
      }
    },
    unlikeSong(song) {
      song.liked = false;
      song.likes -= 1;
      this.unlike_song(song.id);
    },
    launch_chipin_plus_modal() {
      if (this.isAuthenticated()) {
        if (this.my_user_id() !== this.artist.id) {
          this.$bvModal.show("chipin-plus-modal-" + this.artist.id);
        }
      } else {
        this.$store.commit('storeNextUrl', this.$route.path)
        this.$bvModal.show("login-modal");
      }
      // 'chipin-plus-modal-' + this.artist.id"
    },
    launch_chipin_modal() {
      if (this.isAuthenticated()) {
        if (this.my_user_id() !== this.artist.id) {
          this.$bvModal.show("chipin-modal-" + this.artist.id);
        }
      } else {
        this.$store.commit('storeNextUrl', this.$route.path)
        this.$bvModal.show("login-modal");
      }
    },
    show_all_link() {
      if (this.isAuthenticated()) {
        this.$router.push({
          name: "SongProfileList",
          params: {
            username: this.artist.username,
            id: this.artist.userprofile.user_id,
            list: "songs_by_artist",
          }
        });
      } else {
        this.$store.commit('storeNextUrl', this.$route.path)
        this.$bvModal.show("login-modal");
      }
    },
    comment_show_all_link() {
      if (this.isAuthenticated()) {
        this.$router.push({
          name: "ArtistCommentList",
          params: {
            username: this.artist.username,
            id: this.artist.userprofile.user_id
          },
        });
      } else {
        this.$store.commit('storeNextUrl', this.$route.path)
        this.$bvModal.show("login-modal");
      }
    },
    show_all_featured_link() {
      if (this.isAuthenticated()) {
        this.$router.push({
          name: "ArtistProfileList",
          params: {
            username: this.artist.username,
            id: this.artist.userprofile.user_id,
            list: "featured_songs",
          },
        });
      } else {
        this.$store.commit('storeNextUrl', this.$route.path)
        this.$bvModal.show("login-modal");
      }
    },
    tag_click() {
      if (!this.isAuthenticated()) {
        this.$store.commit('storeNextUrl', this.$route.path)
        this.$bvModal.show("login-modal");
      }
    },
    getMeta() {
      return {
            title: `${this.artist.username} - Trackd Music`,
            meta: [
                { name: 'description', content: 'Connect and follow ' + this.artist.username + ' on Trackd Music - ' + this.artist.userprofile.strap_line},
                { property: 'og:title', content: this.artist.username + ' - Trackd Music'},
                { property: 'og:site_name', content: 'Trackd Music'},
                { property: 'og:description', content: 'Connect and follow ' + this.artist.username + ' on Trackd Music - ' + this.artist.username},
                {property: 'og:type', content: 'profile'},
                {property: 'og:url', content: 'https://trackdmusic.com/' + this.artist.username},
                {property: 'og:image', content: this.artist.userprofile.photo }    
            ]
          }
    }
  },
  computed: {
    ...mapGetters("Generic", { random_colour: "getColour" }),
    mostPopularSongsMaxTextLength() {
      /* Determine the max text length for the song name marquee
         Magic numbers determined from trial and error */
      if (window.matchMedia("(min-width: 1200px)").matches) {
        return 18;
      }
      if (window.matchMedia("(min-width: 992px)").matches) {
        return 16;
      }
      if (window.matchMedia("(min-width: 768px)").matches) {
        return 12;
      } else {
        return 14;
      }
    },
    
    featuredSongsMaxTextLength() {
      /* Determine the max text length for the song name marquee */
      if (window.matchMedia("(min-width: 1200px)").matches) {
        return 14;
      }
      if (window.matchMedia("(min-width: 992px)").matches) {
        return 46;
      }
      if (window.matchMedia("(min-width: 768px)").matches) {
        return 22;
      } else {
        return 14;
      }
    },

    songDescriptionMaxTextLength() {
      if (window.matchMedia("(min-width: 1200px)").matches) {
        return 36;
      }
      if (window.matchMedia("(min-width: 992px)").matches) {
        return 46;
      }
      if (window.matchMedia("(min-width: 768px)").matches) {
        return 78;
      } else {
        return 50;
      }
    }
  },
  mounted() {
    this.kochavaEvent("ViewProfile", {})
    var id = this.$route.params.id;

    if (this.$route.name === "Artist") {
      this.artistapi(id)
        .then((res) => {
          this.artist = res.data;

          this.metainfo = this.getMeta()
          this.loaded = true;

          this.liked = res.data.liked;

          if (this.artist.username === this.$store.state.username) {
              this.loaded = true;
              this.liked = res.data.liked;
          }
          else if (window.location.hostname === process.env.VUE_APP_ACCESS_URL) {
            if (this.artist.userprofile.hide_profile) {
              this.$bvModal.show("access-modal");
              this.loaded = false;
              this.liked = res.data.liked;
            }
          } else if (this.artist.userprofile.hide_profile) {
            this.$router.replace("/error");
          } else {
            this.loaded = true;
            this.liked = res.data.liked;
          }

        })
        .catch((error) => {
          error;
          this.$router.replace("/error");
          
        });
    } else {
      this.artistwebapi(id)
        .then((res) => {
          this.artist = res.data;

          this.metainfo = this.getMeta()
          this.loaded = true;

          this.liked = res.data.liked;

          if (this.artist.username === this.$store.state.username) {
              this.loaded = true;
              this.liked = res.data.liked;
          }
          else if (window.location.hostname === process.env.VUE_APP_ACCESS_URL) {
            if (this.artist.userprofile.hide_profile) {
              this.$bvModal.show("access-modal");
              this.loaded = false;
              this.liked = res.data.liked;
            }
          } else if (this.artist.userprofile.hide_profile) {
            this.$router.replace("/error");
          } else {
            this.loaded = true;
            this.liked = res.data.liked;
          }

        })
        .catch((error) => {
          error;
          this.$router.replace("/error");
          
        });
    }
  },
  updated() {
    document
      .getElementById("most-popular-songs")
      .addEventListener("scroll", () => {
        document.getElementById("song-more-btn").style.display = "inline-flex";
      });
  },
};
</script>

<style scoped>

.header-margin {
  margin-top: 120px;
}

.c-collab-badge {
  margin-left: 1rem;
}

.chipin-text--mobile {
  display: none
}

.chipin-locked-icon{
  height: 25px;
}

.add-featured-song {
  height: 150px;
  width: 158px;
  margin-left: -1px;
}

.c-artist-header__strap-line {
  margin-top: 1rem;
}

.l-featured-songs {
  margin-top: 1.5rem;
}

.artist-profile {
  margin-top: 0.5rem;
  text-align: left;
}

.c-artist-photo {
  height: 250px;
  width: 250px;
}

.c-chipin-description {
  font-size: 1.5em;
}

.c-artist-information {
  font-size: 1.13em;
}

.c-featured-song {
  display: flex;
  width: 840px;
  min-width: 720px;
}

.c-most-popular-songs__song:not(:first-of-type) {
  padding-left: 40px;
}

.social-profiles {
  margin-top: 2rem;
}

.popular-artist-song {
  line-height: 21px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-grey {
  color: #9b9b9b;
}

.artist-container {
  padding: 18px 50px 0px 50px;
}

.image-backdrop {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.displayed-on-desktop {
  display: block;
}

.l-social-and-data.displayed-on-desktop {
  display: flex;
}

.photo-border {
  border-radius: 50%;
  border: 5px solid #000;
  box-sizing: border-box;
  margin-top: -150px;
  border: 3px solid white;
}

.border-success-2 {
  border: 2px solid #81cd5c !important;
  color: #81cd5c !important;
  font-size: 14px;
  line-height: 25px;
}

.border-radio {
  border: 2px solid #ffffff !important;
  color: #000000 !important;
  background-color: #fff;
}

.border-radio span {
  text-anchor: start;
}

.border-danger-2 {
  border: 2px solid #e76e66 !important;
  color: #e76e66;
  font-size: 14px;
  line-height: 25px;
}

.btn-square {
  border-radius: 0;
}

.c-song-information {
  display: flex;
  align-items: center;
}

.bw-2 {
  border-width: 2px;
}

.chipin-support-title {
  font-size: 2.25em;
}

.chipin-text {
  position: relative;
  top: -17px;
  font-size: 1.5em;
}

.stats-number {
  font-weight: 200;
  font-size: 48px;
  line-height: 21px;
}

.section-title {
  font-size: 2.2em;
}

.c-chipin-copyright__title {
  font-size: 21.5em;
}

.text-small {
  font-size: 0.6em;
}

.text-smaller {
  font-size: 18px;
  line-height: 21px;
}

.pb-4a,
.py-4a {
  padding-bottom: 4.5rem !important;
}

.pt-4a,
.py-4a {
  padding-top: 4.5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 6rem !important;
}

.py-6 {
  padding-top: 6rem !important;
}

.py-8 {
  padding-bottom: 8rem !important;
}

.py-8 {
  padding-top: 8rem !important;
}

.border-gradient {
  border-top: 2px solid #fff;
  border-image: linear-gradient(to right, white, rgba(0, 0, 0, 0.1)) 1 40%;
  border-image-slice: 1;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  width: 100%;
  margin-bottom: 0.7em;
  margin-top: 0.8em;
}

.more-btn {
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 30px;
  margin-top: 25px;
  opacity: 0.7;
}

.btn-left {
  float: left;
}

.btn-dots {
  padding: 0px;
  margin-right: 30px;
  /* margin-top: 25px; */
  float: right;
}

.c-artist-header__name {
  font-size: 80px;
  line-height: 90px;
  margin-bottom: 2%;

  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.user-type {
  font-size: 36px;
  line-height: 36px;
}

.unfollow-btn {
  background: transparent;
  border: 2px solid white;
  border-radius: 0;
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  height: 42px;
  width: 140px;
}

.follow-btn {
  height: 42px;
  width: 140px;
}

.c-comment-icon {
  /* TODO: unactive link, active it */
  display: none;
  width: 40px;
  margin-top: 0px;
}

.support-img {
  height: 80px;
}

.follow-group {
  position: absolute;
  bottom: 18px;
  right: 0px;
  left: 0px;
  height: 50px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 20px;
}

.stats-section {
  margin-top: 2.5em;
  justify-content: space-between;
}

.c-profile-badges {
  height: 30px;
  width: 140px;
}

.pill-badge {
  width: 180px;
  height: 30px;
  font-size: 14px;
}

.c-artist-header {
  padding-left: 35px;
  padding-right: 35px;
  background-color: rgba(0, 0, 0, 0.3);
}

.user-location {
  font-weight: normal;
  color: #9b9b9b;
  display: inline-flex;
  align-items: center;
}

.user-overview {
  font-weight: normal;
  color: #9b9b9b;
  display: inline-flex;
  align-items: center;
}

.user-bio {
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
}

.social-bar {
  background-color: red;
}

.chipin-support {
  display: inline-flex;
  margin-top: 1em;
}

.genre-box {
  height: 50px;
  border-radius: 20px;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  font-size: 22px;
  line-height: 21px;
  padding: 0 40px 1px 40px;
}

.song-name {
  font-size: 24px;
  line-height: 23px;
}

.artist-name {
  font-weight: 200;
  font-size: 22px;
  color: #9b9b9b;
}

.song-btns {
  height: 20px;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  display: inline-flex;
  align-items: center;
}

.song-des {
  font-weight: 300;
  margin-bottom: 0;

  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 400px;
  height: 3em; /* Fallback for non-webkit */
  font-size: 16px;
  line-height: 1.5em;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.song-header {
  margin-right: 1rem;
  display: inline-flex;
  align-items: center;
  
}

.feature-left {
  width: 70%;
}

.feature-left p {
  width: 100%;
}

.likes-count {
  font-weight: normal;
  font-size: 24px;
  line-height: 21px;
}

.show-all-btn {
  font-weight: 300;
  font-size: 20px;
  margin-top: 0.5em;
}

.recent-text {
  font-weight: 300;
  font-size: 2.01em;
  line-height: 21px;
}

.artist-info {
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.152972px;
}

.comment-des {
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 1.5em;
}

chipin-link {
  text-decoration: underline;
}

.feature-div {
  display: inline-block;
  width: 100%;
}

.genre-badge {
  display: none;
}

.song-card {
  padding: 10px 0;
  margin-bottom: 25px;
}

.song-card:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  cursor: pointer;
}
.popular-artist-name {
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  color: #9b9b9b;
}

.song-locked {
  position: absolute;
  right: 6px;
  top: 6px;
  z-index: 100;
}

.artist-verified {
  position: absolute;
  right: 15px;
  top: -95px;
  z-index: 100;
  width: 30px;
  height: 30px;
}

.support-gradient {
  display: none;
}
.tap-mobile {
  display: none;
}
.rtcl-btns {
  background-color: #f2b471;
  border-radius: 20px !important;
  padding-top: 7px;
  padding-left: 7px;
  height: 16px;
  width: 28px;
  font-size: 12px;
  align-items: center;
}
.view-song-btn {
  display: none;
  width: 150px;
}
.card-carousel-cards {
  display: inline-flex;
}
.overflow-auto::-webkit-scrollbar {
  display: none;
}
.song-more-btn {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  margin-left: 15px;
  border: 3px solid #4a4a4a;
  background: transparent;
  display: none;
  align-items: center;
  justify-content: center;
}

.song-more-btn img {
  height: 50px;
}

.song-more-btn > div > p {
  margin-bottom: 0;
  color: #9b9b9b;
}
.displayed-on-mobile {
  display: none;
}
.top-arrow {
  margin: auto;
  margin-bottom: 10px;
}

.l-social-and-data {
  margin-bottom: 3rem;
  margin-top: 1.5rem;
}

.c-most-listened {
  margin-top: 3rem;
}

.c-arrow-up-disk {
  height: 21px;
}

.c-song-indicators {
  display: inline-flex;
  align-items: center;
}

.more-icons.heart {
  width: 25px;
  height: 27px;
  margin-right: 24px;
}

#c-song-from-artist {
  margin-top: 5px;
}

.btn-18 {
  margin-right: 5px;
  color: #9b9b9b;
  border-color: #9b9b9b;
  padding-left: 3px;
  padding-right: 3px;
}

.l-desktop-genres {
  display: block;
}

.l-mobile-genres {
  display: none;
}

.l-comments-section {
  padding-bottom: 3rem;
}

.l-hall-of-fame {
  margin-bottom: 0px;
}

.c-comment-icon {
  margin-right: 1rem;
}

.c-artist-bio {
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
}

.l-popular-artist-song-description {
  position: relative;
  top: -8px;
  line-height: 10px;
}

.l-wall-of-fame {
  justify-content: space-between;
}

.image-backdrop {
  height: 320px;
}

.btn-left {
  margin-top: unset;
}

.c-supporter-badge {
  margin-left: 6%;
  border: 1px solid #E76E66
}

.c-radio-badge {
  margin-left: 6%;
  cursor: pointer;
}

.c-radio-badge:hover {
  cursor: hand;
  color: #fff !important;
  background: #000;
}

.c-radio-badge img {
  height: 14px;
  width: 16px;
  position: relative;
  top: -1px;
}

.c-radio-badge:hover img {
  filter: invert(1);
}

.c-photo-container {
  display: auto;
  transform: translateY(15%);
}

.c-feature-song-title {
  display: inline-block;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-btn-follow {
  display: flex;
  justify-content: center;
}

.tap-mobile {
  display: block;
}

.tap-desktop {
  display: none;
}

.c-artist-stat {
  color: #9b9b9b;
}

.support-img {
  margin-right: 0.5rem;
}

.c-header-actions {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.l-wall-of-fame-container {
  margin-top: 40px;
}

.c-last-artist-login,
.c-artist-location {
  width: 100%;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.radio-btn {
    width: 90px;
    height: 18px;
    font-size: 8px;
    line-height: 18px;
    margin-top: 5px;
  }

  .radio-symbol {
    width: 12px;
    transform: translateY(-1px);
  }

@media (min-width: 375px) {
  .song-name {
    font-size: 16px;
    line-height: 21px;
    font-weight: 700;
  }
}

@media (max-width: 375px) {
  .likes-count {
    padding-top: 2px;
  }

  .c-artist-location {
    margin-left: 3px;
  }

  .c-artist-location-logo {
    margin-right: 13px;
  }

  .chipin-support-title {
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
  }

  .c-profile-badges {
    padding: 0 15px 10px 12px !important;
    height: 13px;
    width: 71px;
    font-size: 8px;
    line-height: 11px;
    transform: translateX(2px);
  }

  .border-gradient {
    border-top: 2px solid #fff;
    border-image: linear-gradient(to right, white, rgba(0, 0, 0, 0.1)) 1 40%;
    border-image-slice: 1;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    width: 100%;
    margin-bottom: 0.7em;
    margin-top: 0.5em;
  }

  .section-heading {
    font-size: 24px;
  }

  .rtcl-btns {
    background-color: #F5A623;
    border-radius: 20px !important;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    height: 10px;
    width: 21px;
    font-size: 8px;
    align-items: center;
  }
  
}

@media (max-width: 565px) {
  .chipin-text.chipin-text--mobile {
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
  }

  .chipin-text:not(.chipin-text--mobile) {
    display: none;
  }

  .song-card {
    padding: 10px 0;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .l-wall-of-fame-container {
    margin-top: 20px;
  }

  .c-feature-song-title {
    max-width: 38vw;
  }

  .c-most-listened {
    margin-top: 1rem;
  }

  .c-artist-stat {
    margin-right: unset;
    padding-right: 0px;
    padding-left: 0px;
  }

  .btn-left {
    margin-top: 10px;
    margin-left: 10px;
    width: 30px;
    height: 30px;
  }

  .follow-group {
    position: absolute;
    bottom: 0;
    right: 15px;
    height: 50px;
    width: unset;
    margin-bottom: 5%;
    padding-top: unset;
  }

  .c-artist-photo {
    height: 140px;
    width: 140px;
  }

  .more-btn {
    background-color: rgba(0, 0, 0, 0.2);
    width: 30px;
    height: 30px;
    margin-top: unset;
    margin-right: 10px;
  }

  .c-artist-header__name {
    font-size: 28px;
    line-height: 36px;
    font-weight: 300;
    margin-bottom: 2%;
  }

  .c-artist-header__strap-line {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 2%;
    margin-top: unset;
    height: 20px;
  }

  .c-btn-follow {
    height: 24px;
    width: 84px;
    font-size: 15px;
    padding: unset;
    padding-left: 20px !important;
    line-height: unset;
  }

  .c-comment-icon {
    width: 25px;
    margin-top: 0px;
    margin-right: 0.5rem;
  }

  .artist-profile {
    padding-left: unset;
    padding-right: 3px;
    margin-top: unset;
    margin-left: -7px;
  }

  .artist-container {
    padding: 30px;
    padding-top: 5px;
  }

  .photo-border {
    margin-top: -100px;
  }

  .stats-number {
    font-weight: 300;
    font-size: 25px;
    line-height: 21px;
  }

  .text-smaller {
    font-weight: 400;
    font-size: 9px;
    line-height: 10px;
  }

  .stats-section {
    margin-top: 0.7em;
    padding-right: 15%;
    margin-left: -8px;
    margin-right: 0px;
  }

  .stats-number {
    margin-bottom: 5px;
  }

  .social-icons {
    width: 20px;
    height: 20px;
  }

  .social-profiles {
    margin-top: 0px;
  }

  .c-last-artist-login,
  .c-artist-location {
    margin-left: 3px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
  }

  .c-last-artist-login {
    margin-left: 14px;
  }

  .c-artist-location-logo {
    height: 18px;
    margin-right: 12px;
  }

  .c-last-artist-login-logo {
    height: 15px;
    margin-right: 5px;
  }

  .l-social-and-data {
    margin-bottom: 2rem;
    margin-top: 1rem;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
  }

  .support-img {
    height: 35px;
    width: 111.73px;
    margin-top: 10px;
  }

  .c-artist-bio {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    color: #9B9B9B;
  }

  .chipin-support {
    margin-top: unset;
    margin-bottom: 12px;
  }

  .chipin-support-title {
    font-size: 1.1em;
    margin-bottom: 15px;
  }

  .chipin-text {
    font-size: 0.8em;
  }

  .support-btn {
    padding-top: unset;
    padding-right: unset;
  }

  .section-title {
    font-size: 1.5em;
    font-weight: 300;
    line-height: 21px;
  }

  .c-chipin-copyright__title {
    font-size: 0.7em;
    font-weight: 300;
    line-height: 21px;
  }

  .show-all-btn {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;

    margin-top: unset;
  }

  .c-most-popular-songs__song:not(:first-of-type) {
    padding-left: 16px;
  }

  .c-arrow-up-disk {
    height: 12px;
  }

  .popular-artist-song {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
  }

  .popular-artist-name {
    display: none;
  }

  .song-more-btn {
    margin-left: unset;
    width: 80px;
    height: 80px;
  }

  .song-more-btn img {
    height: 30px;
  }

  .song-more-btn p {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
  }

  .l-featured-songs {
    margin-top: 1rem;
  }

  .song-header > img {
    margin-right: 0.5rem;
    height: 20px;
  }

  .feature-div {
    display: flex;
    justify-content: space-between;
  }

  .more-icons {
    height: 20px;
  }

  .more-icons.heart {
    margin-right: 2px;
  }

  .likes-count {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-left: 12px;
    margin-right: 0.7rem;
  }

  .btn-18 {
    /* padding-top: 2px !important; */
    /* margin-left: unset; */
    margin-right: 0.4rem;
    font-size: 11px;
  }

  .song-btns {
    height: 16px;
  }

  .artist-name {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
  }

  .song-recorder {
    width: 31px;
    height: 39px;
    margin-left: 32px;
  }

  .song-des {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }

  .l-desktop-genres {
    display: none;
  }

  .l-mobile-genres {
    display: flex;
    flex-wrap: inherit;
    margin-right: 15px;
  }

  .l-mobile-genres > div {
    white-space: nowrap;
    display: contents;
  }

  .l-mobile-genres > div > img {
    margin-top: 3px;
  }

  .comment-des {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 1em;
  }

  .l-comments-section {
    padding-bottom: 1.5rem;
  }

  .c-artist-information > span,
  .recent-text,
  .contrib-left > p,
  .contrib-middle > p,
  .contrib-right > p,
  .c-chipin-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }

  .c-featured-song {
    min-width: unset;
  }

  .artist-page {
    height: 200px;
  }
  .c-collab-badge {
    margin-left: 0.2rem;
  }

  .displayed-on-mobile {
    display: block;
  }

  .displayed-on-desktop {
    display: none;
  }

  .l-social-and-data.displayed-on-desktop {
    display: none;
  }

  .l-last-access-and-social {
    justify-content: space-between;
  }

  .displayed-on-mobile.social-icons {
    margin-right: 1.3rem;
  }

  .social-icons.twitter,
  .social-icons.youtube {
    width: 22px;
    height: 22px;
  }

  .l-location-container {
    margin-top: 15px;
  }

  .image-backdrop {
    height: 200px;
  }

  .l-divisor-desc-support {
    margin-bottom: unset;
  }

  .btn-back {
    padding-top: unset;
    padding-left: unset;
  }

  .c-radio-badge {
    font-size: 8px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .c-radio-badge img {
    height: 8px;
    width: 10px;
    position: relative;
    top: 0px;
  }

  .border-radio {
    border: 2px solid #000 !important;
    margin-left: 3px;
  }

  .border-radio:hover {
    border: 2px solid #fff !important;
  }

  /* .artist-verified {
    width: 40px;
    height: 40px;
  } */
}

/* EXCEPTIONAL BREAKPOINT FOR VERY LOW RESOLUTION */
@media (max-width: 404px) {

  .top-arrow {
    margin: auto;
    margin-bottom: 10px;
    margin-top: 4px;
  }

  .stats-section {
    padding-right: unset;
    margin-top: 0.4em;
  }

  .c-profile-badges {
    margin-left: 1%;
  }

  .artist-container {
    padding: 20px;
    padding-top: 5px;
  }

  .popular-artist-song {
    font-size: 12px;
    line-height: 24px;
    font-weight: 400;
  }

  .follow-group {
    padding-right: 10px;
  }

  .displayed-on-mobile.social-icons {
    margin-right: 1rem;
  }

  .support-btn {
    padding-left: 0px;
  }

  .song-btns {
    height: 5px;
  }

  .btn-18 {
    height: 16px;
    font-size: 10px;
  }

  .comment-des {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 0.52em;
  }

  .stats-number {
    margin-bottom: 3px;
  }

  .l-location-container {
    margin-top: 16px;
  }

  .chipin-support {
    margin-top: -7px;
    margin-bottom: 12px;
  }

  .song-recorder {
    width: 31px;
    height: 39px;
    margin-left: 32px;
    transform: translateY(-5px);
  }
  
}
@media (max-width: 576px) {

  .c-profile-badges {
    height: 13px;
    width: 71px;
    font-size: 8px;
    line-height: 11px;
  }

  .song-locked {
    position: absolute;
    right: 4px;
    top: -4px;
    width: 20px !important;
    z-index: 100;
  }

  .l-empty-space-wall-of-fame {
    margin-bottom: 120px;
  }

  .artist-verified {
    position: absolute;
    right: 25px;
    top: -95px;
    z-index: 100;
    width: 30px;
  }

  .song-info-adjust {
    margin-top: -5px;
  }

  .c-song-indicators {
    display: inline-flex;
    align-items: center;
    margin-top: -8px;
  }
}

@media (min-width: 576px) {
  .l-desktop-genres {
    display: flex;
  }
  .support-img {
    margin-right: 1rem;
  }

  .l-empty-space-wall-of-fame {
    margin-bottom: 120px;
  }

  .tap-mobile {
    display: none;
  }

  .tap-desktop {
    display: block;
  }

  .l-mobile-genres {
    display: none;
  }

  .genre-box {
    height: 35px;
    font-size: 18px;
    line-height: 21px;
    font-weight: 300;
    white-space: nowrap;
  }

  .artist-verified {
    position: absolute;
    right: 20px;
    top: -100px;
    z-index: 100;
    width: 30px;
  }

  .c-profile-badges {
    padding: 0 15px 13px 15px !important;
    height: 13px;
    width: unset;
    font-size: 8px;
    line-height: 13px;
  }

  .song-name {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
  }

  .radio-btn {
    width: 110px;
  }


}

@media (min-width: 768px) {

  .wide {
    margin-left: -40px;
    margin-right: -35px;
  }

  .artist-verified {
    position: absolute;
    right: 10px;
    top: -140px;
    z-index: 100;
    width: 40px;
    height: 40px;
  }

  .displayed-on-mobile {
    display: block;
  }

  .follow-group {
    padding-right: 35px;
  }

  .c-comment-icon {
    margin-right: 25px;
  }

  .l-empty-space-wall-of-fame {
    margin-top: 30px;
    margin-bottom: 120px;
  }

  .artist-container {
    padding: 39px 50px 0px 50px;
  }

  .stats-section {
    margin-top: 1.5em;
    justify-content: space-between;
  }

  .artist-page {
    height: 320px;
  }

  .artist-profile {
    margin-top: unset;
    text-align: left;
  }

  .l-hall-of-fame {
    margin-bottom: 0px;
  }
  .c-artist-stat {
    text-align: center;
  }

  .stats-section {
    padding-left: 5%;
  }

  .displayed-on-desktop {
    display: none;
  }

  .c-artist-header__name {
    font-size: 48px;
    font-weight: 300;
    line-height: 58px;
  }

  .c-artist-header__strap-line {
    font-size: 24px;
    font-weight: 300;
    line-height: 36px;
  }

  .c-artist-photo {
    width: 200px;
    height: 200px;
    border: 3px solid white;
  }

  .btn-left {
    margin-top: 5px;
    margin-left: 15px;
  }

  .c-profile-badges {
    height: 30px;
    width: 180px;
    font-size: 14px;
    line-height: 28px;
  }

  .c-profile-badges:first-child {
    margin-left: -0.5rem;
  }

  .stats-number {
    font-size: 42px;
    font-weight: 200;
    line-height: 33px;
  }

  p.stats-number {
    margin-bottom: 0.2em;
  }

  .text-smaller {
    font-size: 16px;
    font-weight: 300;
    line-height: 18.75px;
  }

  .c-artist-stat {
    padding-right: unset;
  }

  .l-social-and-data {
    margin-bottom: 1.3rem;
    margin-top: 1.5rem;
  }

  .social-icons {
    width: 33.08px;
    height: 33.08px;
  }

  .social-icons.twitter,
  .social-icons.youtube {
    width: 35.08px;
    height: 35.08px;
  }

  .c-last-artist-login,
  .c-artist-location {
    font-size: 24px;
    line-height: 21px;
    font-weight: 400;
  }

  .c-last-artist-login {
    margin-left: 5px;
  }

  .c-artist-location-logo {
    height: 30px;
    margin-right: 25px;
  }

  .c-last-artist-login-logo {
    height: 30px;
    margin-right: 10px;
  }

  .social-profiles {
    margin-top: unset;
  }

  .l-last-access-and-social {
    justify-content: space-between;
  }

  .displayed-on-mobile.social-icons {
    margin-right: 1rem;
  }

  .l-location-container {
    margin-top: 22px;
  }

  .l-social-and-data.displayed-on-desktop {
    display: none;
  }

  .c-artist-bio {
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
    color: #ffffff;
  }

  .support-img {
    height: 60px;
    width: 193px;
  }

  .support-btn {
    padding-top: unset;
    padding-right: unset;
    margin-left: -12px;
  }

  .support-artist {
    margin-left: 12px;
  }

  .chipin-support {
    margin-top: 10px;
    align-items: start;
  }

  .chipin-support-title {
    font-size: 28px;
    line-height: 36px;
    font-weight: 300;
  }

  .chipin-text {
    font-size: 16px;
    line-height: 36px;
    font-weight: 300;
  }

  .section-title {
    font-size: 36px;
    line-height: 21px;
    font-weight: 200;
    margin-bottom: 1.7rem;
  }

  .section-heading {
    font-size: 36px;
  }

  .c-chipin-copyright__title {
    font-size: 24px;
    font-weight: 200;
    line-height: 21px;
  }
  .c-most-listened {
    margin-top: 2.9rem;
  }

  .show-all-btn {
    font-weight: 300;
    font-size: 24px;
    line-height: 21px;

    margin-top: unset;
  }

  .popular-artist-song {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
  }

  #c-song-from-artist {
    margin-top: 10px;
  }

  .l-popular-artist-song-description {
    margin-top: 0.1rem;
  }

  .c-song-indicators {
    display: inline-flex;
    align-items: center;
    margin-right: 8px;
  }

  .c-featured-song {
    /* min-width: unset; */
  }

  .l-featured-songs {
    margin-top: -0.2rem;
  }

  .featured-songs-list{
    margin-top: 18px;
  }
  
  .chipin-locked-icon {
    height: 30px;
  }

  .song-info-adjust {
    margin-left: 2px;
  }

  .song-name {
    font-size: 24px;
    line-height: 21px;
    font-weight: 400;
  }

  .artist-name {
    font-size: 24px;
    line-height: 34px;
    font-weight: 300;
  }

  .btn-18 {
    margin-right: 2.2rem;
  }

  .likes-count {
    margin-right: 0.6rem;
    font-size: 24px;
    line-height: 21px;
  }

  .genre-box {
    height: 35px;
    font-size: 18px;
    line-height: 21px;
    font-weight: 300;
  }

  .comment-des {
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 1em;
  }

  .l-desktop-genres {
    white-space: nowrap;
    display: flex;
    margin-top: 22px;
  }

  .c-btn-follow {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;

    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
  }

  .c-chipin-description {
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
  }

  .recent-text {
    font-weight: 300;
    font-size: 32px;
    line-height: 21px;
  }

  .c-artist-information > span {
    font-weight: 200;
    font-size: 18px;
    line-height: 24px;
  }

  .contrib-left > p,
  .contrib-middle > p,
  .contrib-right > p {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
  }

  .c-photo-container {
    display: auto;
    transform: translate(-11%, 0%);
  }

  .more-icons.heart {
    width: 33px;
    height: 30px;
    margin-right: 36px;
  }

  .song-des {
    font-size: 18px;
    line-height: 23px;
  }

  .song-des-row {
    margin-top: -5px;
  }

  .rtcl-btns {
    background-color: #f2b471;
    border-radius: 20px !important;
    padding-top: 7px;
    padding-left: 9px;
    height: 20px;
    width: 36px;
    font-size: 14px;
    align-items: center;
    margin-top: -7px;
  }

  .song-card {
    padding: 10px 0;
    margin-bottom: 17px;
    /* margin-left: -25px; */
    padding-right: 23px;
    padding-left: 25px;
  }

  .top-arrow {
    margin: auto;
    margin-bottom: 10px;
    margin-top: 13px;
  }

  .radio-btn {
    width: 185px;
    height: 30px;
    font-size: 14px;
    line-height: 28px;
    margin-top: 0;
    transform: translateX(20px);
  }

  .radio-symbol {
    width: 22px;
    transform: translateY(-1.5px);
  }
  

}

@media (min-width: 992px) {
  .artist-verified {
    position: absolute;
    right: 20px;
    top: -140px;
    z-index: 100;
    height: 40px;
    width: 40px;
  }

  .displayed-on-mobile {
    display: none;
  }

  .displayed-on-desktop {
    display: inline-block;
  }

  .l-social-and-data.displayed-on-desktop {
    display: flex;
  }

  .c-artist-header__name {
    font-size: 60px;
    font-weight: 300;
    line-height: 70px;
  }

  .c-artist-header__strap-line {
    font-size: 28px;
    font-weight: 300;
    line-height: 36px;
  }

  .btn-dots {
    margin-top: 12px;
  }

  .c-btn-follow {
    width: 132px;
    height: 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 28.13px;
    padding-top: 3px;
  }

  .c-artist-stat {
    padding-right: 0px;
    padding-left: 0px;
  }

  .c-artist-photo {
    width: 200px;
    height: 200px;
  }

  .c-photo-container {
    transform: translateY(20%);
  }

  .l-last-access-and-social {
    justify-content: space-between;
  }

  .social-icons {
    width: 33.08px;
    height: 33.08px;
  }

  .social-icons.twitter,
  .social-icons.youtube {
    width: 35.08px;
    height: 35.08px;
  }

  .displayed-on-mobile.social-profiles {
    margin-top: unset;
    width: 16rem;
    justify-content: space-between;
    display: flex;
  }

  .l-location-container {
    margin-top: 20px;
  }

  .show-all-btn {
    font-size: 24px;
    font-weight: 300;
    line-height: 21px;
  }

  .popular-artist-name {
    line-height: 24px;
  }

  .song-recorder {
    width: 52px;
    height: 70px;
  }

  .song-name {
    line-height: 21px;
    /* margin-left: 16px; */
  }

  .artist-name {
    font-weight: 100;
    font-size: 18px;
    line-height: 21px;
  }

    .c-last-artist-login,
    .c-artist-location {
      font-size: 18px;
      line-height: 21px;
      font-weight: 400;
    }

  .btn-18 {
    width: 29px;
    padding-left: 6px;
  }

  .likes-count {
    margin-right: 0.6rem;
  }

  .l-desktop-genres {
    white-space: nowrap;
    display: flex;
    margin-bottom: 4rem;
  }

  .genre-box {
    height: 35px;
  }

  .contrib-left > p,
  .contrib-middle > p,
  .contrib-right > p {
    font-weight: 400;
    font-size: 20px;
    line-height: 21px;
  }
.stats-number {
    font-size: 48px;
    font-weight: 200;
    line-height: 37px;
  }

  p.stats-number {
    margin-bottom: 0.2em;
  }

  .text-smaller {
    font-size: 18px;
    font-weight: 300;
    line-height: 18.75px;
  }

  .social-profiles {
    justify-content: space-between;
    align-items: flex-end;
    display: flex;
    margin-bottom: 1.2rem;
    padding: 0;
    transform: translateX(20px);
  }

  .top-arrow {
    margin: auto;
    margin-bottom: 10px;
    margin-top: none;
  }


  .radio-symbol {
    width: 22px;
    transform: translateY(-1.5px);
  }
}

@media (min-width: 1200px) {
  .displayed-on-mobile {
    display: none;
  }

  .artist-verified {
    position: absolute;
    right: 40px;
    top: -147px;
    z-index: 100;
    width: 54px;
    height: 54px;
  }

  .displayed-on-desktop {
    display: block;
  }

  .follow-group {
    max-width: 1060px;
    padding-right: 0px;
  }

  .l-social-and-data.displayed-on-desktop {
    display: flex;
  }

  .c-comment-icon {
    display: none;
  }

  .c-artist-photo {
    width: 250px;
    height: 250px;
    transform: translate(-33px, -10px);
    border: 5px solid white;
  }

  .c-artist-header__name {
    font-size: 60px;
    font-weight: 300;
    line-height: 70px;
  }

  .c-artist-header__strap-line {
    font-size: 28px;
    font-weight: 300;
    line-height: 36px;
  }

  .c-photo-container {
    transform: translate(5px, 10px);
  }

  .c-profile-badges:first-child {
    margin-left: -1.5em;
    transform: translateY(-7px);
  }

  .btn-dots {
    margin-top: 12px;
  }

  .c-btn-follow {
    width: 132px;
    height: 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 28.13px;
    padding-top: 3px;
  }

  .stats-section {
    padding-left: 5%;
    margin-top: 1.9em;
    justify-content: space-between;
    transform: translateX(-36px);
  }

  .c-artist-stat {
    padding-right: 0px;
    padding-left: 0px;
  }

  

  .stats-number {
    font-size: 48px;
    font-weight: 200;
    line-height: 35px;
  }

  p.stats-number {
    margin-bottom: 0.2em;
  }

  .radio-btn {
    line-height: 27px;
    margin-top: -7px;
    transform: none;
  }


  .text-smaller {
    font-size: 18px;
    font-weight: 300;
    line-height: 18.75px;
  }

  .btn-left {
    margin-top: 2px;
  }

  .social-profiles {
    justify-content: space-between;
    align-items: flex-end;
    display: flex;
    margin-bottom: 1.3rem;
    padding: 0;
    transform: translateX(3px);
  }

  .c-last-artist-login,
  .c-artist-location {
    font-size: 24px;
    line-height: 21px;
    font-weight: 400;
  }

  .c-artist-location-logo {
    height: 30px;
    margin-right: 7px;
  }

  .l-divisor-desc-support {
    margin-top: 20px;
  }

  .c-artist-bio {
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
    color: #ffffff;
    margin-top: 22px;
  }

  .artist-bio-adjust {
    padding-left: 13px;

  }

  .song-more-btn {
    width: 150px;
    height: 150px;
  }

  /* .c-featured-song {
    max-width: 50%;
  } */

  .c-featured-song {
    display: flex;
    /* width: 840px; */
    min-width: unset;
  }

  .song-name {
    font-size: 24px;
    line-height: 21px;
    font-weight: 400;
  }

  .song-des {
    font-size: 18px;
  }

  .likes-count {
    margin-right: 0.6rem;
  }

  .artist-name {
    font-size: 24px;
    line-height: 21px;
    font-weight: 200;
  }

  .genre-box {
    height: 35px;
    font-size: 18px;
    line-height: 21px;
    font-weight: 400;
  }

  .contrib-left > p,
  .contrib-middle > p,
  .contrib-right > p {
    font-weight: 400;
    font-size: 20px;
    line-height: 21px;
  }

  .support-img {
    height: 80px;
    width: 257px;
  }

  .chipin-support {
    margin-top: 5px;
    align-items: start;
  }

  .chipin-support-title {
    font-weight: 300;
    font-size: 36px;
    line-height: 48px;
  }

  .chipin-text {
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
  }

  .l-most-popular-songs {
    margin-top: 30px;
  }

  .song-recorder {
    height: 75px;
    width: 60px;
  }

  .social-icons {
    width: 40px;
    height: 40px;
  }

  .wide {
    margin-left: -41px;
    margin-right: -35px;
  }

  .c-supporter-badge {
    border: 2px solid #E76E66
  }

  .c-arrow-up-disk {
    height: 21px;
    margin-top: 22px;
  }

  .song-info-adjust {
    margin-left: 2px;
  }

  .feature-left {
    width: 68%;
  }

  .c-song-indicators {
    display: inline-flex;
    align-items: center;
    margin-right: none;
  }

  .l-desktop-genres {
    white-space: nowrap;
    display: flex;
    margin-top: 21px;
    margin-bottom: 2em;
  }

  .comment-des {
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 1em;
  }

  .comment-section {
    margin-top: 32px;
  }

  .l-comments-section {
    padding-bottom: 2.2rem;
  }

  .artist-location-adjust {
    transform: translateX(-9px);
  }


}

@media (max-width: 575.98px) {
  .add-featured-song {
    height: 115px;
    width: 115px;
  }
}

@media (max-width: 450px) {
  .c-photo-container {
    padding-left: unset;
    transform: translate(-1%, 12%);
  }

  .c-feature-song-title {
    max-width: 36vw;
  }
}

@media (max-width: 414px) {
  .add-featured-song {
    height: 95px;
    width: 95px;
  }
}

@media (max-width: 375px) {
  .artist-verified {
    right: 35px;
    width: 32px;
    height: 32px;
    top: -102px;
  }

  .radio {
    width: 10px;
    height: 12px;
    font-size: 8px;
  }

  .radio > span {
    font-size: 8px !important;
  }

  .add-featured-song {
    height: 80px;
    width: 80px;
  }

  .l-social-and-data {
    margin-bottom: -0.2rem;
    margin-top: -0.2rem;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
  }

  .c-last-artist-login-logo {
    height: 13px;
    margin-right: 5px;
  }

  .featured-songs-list{
    margin-top: -3px;
  }

  .l-mobile-genres {
    display: flex;
    flex-wrap: inherit;
    margin-right: 15px;
    margin-bottom: 20px;
  }
  
}

@media (max-width: 320px) {
  .artist-verified {
    position: absolute;
    right: -10px;
    top: -90px;
    z-index: 100;
    width: 25px;
  }

  .artist-profile {
    padding-left: 10px;
  }

  .c-profile-badges {
    padding: 0px 6px 12px 6px !important;
  }

  .displayed-on-mobile.social-icons {
    margin-right: 10px;
  }

  .chipin-support-title {
    font-size: 0.9em;
    font-weight: 300 !important;
    margin-bottom: 15px;
  }

  .add-featured-song {
    height: 65px;
    width: 65px;
  }
}
</style>
