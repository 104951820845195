<template>
  <b-modal
    id="add-featured-song-modal"
    size="lg"
    centered
    hide-footer
    title-sr-only
    content-class="bg-dark text-light text-center"
    header-close-variant="light"
    modal-class="dot-dot-dot-modal"
    title="Login"
    @hide="onClose"
  >
    <template #modal-header="{ close }">
      <b-container fluid>
        <b-row class="text-center">
          <!-- Required for equal spacing -->
          <b-col></b-col>

          <b-col cols="12">
            <img
              src="../../../assets/trackd-icon-colours.svg"
              class="mb-4 float-right mt-2"
              @click="close()"
              height="35"
              alt="Logo"
            />
            <h4 class="font-weight-lighter text-left h2 mt-5 mb-5 mb-md-0">
              Add Featured Song
            </h4>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <b-container fluid class="pb-0">
      <b-row>
        <div class="col-11 pl-3">
          <div v-if="success">
            <p class="font-weight-light text-success">
              Song has been added to featured list.
            </p>
          </div>
          <div v-if="error">
            <p class="font-weight-light text-danger">
              There was a problem. please try again.
            </p>
          </div>

          <b-form class="pb-2 mr-3 add-to-playlist-modal-content">

            <b-button v-for="(song, index) in this.songs" :key="index"
                class="py-2 px-1 mb-2 text-left text-light font-weight-light btn-add-link"
                block
                @click="Add(song.id)"
                variant="link"
                >
                <img
                  src="../../../assets/ancillary/new-playlist.svg"
                  class="pr-2 ml-1 move-up-icon text-left"
                  height="60"
                  alt="Logo"
                />
                {{ song.name }}
            </b-button>

          </b-form>
        </div>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AddFeaturedSong",
  props: [],
  data() {
    return {
      success: false,
      error: false,
      songs: [],
    };
  },
  methods: {
    ...mapActions("Artist", ["get_featured_picker_songs", "add_featured_song"]),
    ...mapGetters(["my_user_id", "isAuthenticated"]),
    onClose() {
      this.success = false;
      this.error = false;
    },
    Add(id) {
      this.error = false;
      this.success = false;

      this.add_featured_song(id)
        .then((res) => {
          res;
          this.success = true;
          this.responseMsg = res.data.status;
          this.responseClass = "text-success";

          setTimeout(() => {
            this.$bvModal.hide("add-featured-song-modal");
            this.$router.go(0);
            
          }, 2500);
        })
        .catch((error) => {
          

          if (error.data.errors) {
            this.responseMsg = error.data.errors[0];
            this.responseClass = "text-danger";
          } else if (error.data.non_field_errors) {
            this.responseMsg = error.data.non_field_errors[0];
            this.responseClass = "text-danger";
          } else {
            this.responseMsg = error.data.message;
            this.responseClass = "text-danger";
          }
        });
    },
  },
  mounted() {
    this.success = false;
    this.error = false;
    if (this.isAuthenticated()) {
      this.get_featured_picker_songs().then((res) => {
        this.songs = [...res.data.songs];
      });
    }
  },
};
</script>

<style>
.dot-dot-dot-modal .modal-content {
  width: 400px;
  margin: auto;
}

.add-to-playlist-modal-content {
  max-height: 400px;
  overflow: scroll;
}

.forget-link {
  text-decoration: none;
}
.rect-box {
  border-radius: 0 !important;
}

.text-underline {
  text-decoration: underline;
}
.text-underline:hover {
  text-decoration: none;
}

.btn-rounded {
  border-radius: 10px;
}

.h2 {
  font-weight: 400;
  font-size: 24px;
  line-height: 21px;
}

.confirm-text {
  font-weight: 300;
  font-size: 24px;
  line-height: 21px;
  color: #9b9b9b;
}

.btn-add-link {
font-size: 18px;
line-height: 21px;
}

.btn-add-link:hover {
  border-radius: 20px;
  background-color: #222222;
  text-decoration: none;
}

.text-input {
  background-color: #222222;
  color: #9b9b9b;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  font-weight: 300;
}

.form-control:focus {
  background-color: #222222;
  color: #9b9b9b;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #9b9b9b;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #9b9b9b;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #9b9b9b;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #9b9b9b;
}

.b-dropdown-form:focus {
  outline: none !important;
}
</style>
